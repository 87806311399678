<template>
    <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
      <v-row justify="center" class="mb-8">
        <v-col class="mt-14 pl-0 pr-0 text-left" cols="12">
          <h1 class="candidate-details__title">Detalle del proceso disciplinario</h1>
        </v-col>
        <v-col class="px-0" cols="12">
          <v-card elevation="0" class="px-6 py-6" outline style="border-radius: 15px"> 
            <p class="text-left"> Datos del procesado </p>
            <v-row class="mb-5">
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Nombre completo </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.full_name }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Número de documento </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.document_type }} {{ info.document_number }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Fecha de expedición </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.document_expedition_date }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Correo electrónico </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.email }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Teléfono</i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.phone }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Fecha de nacimiento </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.birth_date }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Fecha de creación del proceso </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.fecha }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Hora de creación del proceso </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.hora }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Estado del proceso </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ getNameStatus(info.status_id) }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="mb-1 candidate-details__data_title">
                  <i> Motivo </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.motivo }}
                </p>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            
            <p class="text-left"> Datos del creador del proceso </p>
            <v-row>
            <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Nombre completo </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.name_digitador }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Correo electrónico </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.email_digitador  }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Cargo </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.cargo_digitador }}
                </p>
                <v-divider></v-divider>
              </v-col>
            </v-row>

           </v-card>
        </v-col>

      </v-row>
      </div>
  
      </div>
</template>
<script>
export default {
  data() {
    return {
      listDescriptions: [],
      listStatus: ["Pendiente entrevista", "Contratado", "No contratado", "Sin respuesta"],
      form: {
        status: null,
        description: ""
      },
      info: {
      },
      search: "",
    };
  },
  methods: {
    getNameStatus(status) {
      switch (status) {
        case 1:
          return "Citado";
        case 8:
          return "En proceso";
        case 4:
          return "Finalizado";
        default:
          return "Desconocido";
      }
    },

  },
  mounted() {

  },
  created(){
    var data = localStorage.getItem("infoDisciplinary");
    this.info =  JSON.parse(data);
  }
};
</script>
<style>
.candidate-details__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-details__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}

.candidate-details__table {
  height: 420px;
  overflow-y: scroll;
}

</style>
